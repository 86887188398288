export default (state = { status: "", message: "" }, action) => {
  switch (action.type) {
    case "LOGIN_FAILED":
      return action.payload;
    case "ERROR":
      return action.payload;
    case "SEND_EMAIL_SUCCESS":
      return action.payload;
    case "CREATE_NEW_INVOICE":
      return action.payload;
    case "UPDATE_INVOICE":
      return action.payload;
    case "CREATE_NEW_ORDER_PENJUALAN":
      return action.payload;
    case "CREATE_NEW_ORDER_PEMBELIAN":
      return action.payload;
    case "SUCCESS_NEW_CUSTOMER":
      return action.payload;
    case "CREATE_NEW_FINANCING":
      return action.payload;
    case "APPROVE_FINANCING":
      return action.payload;
    case "REJECT_FINANCING":
      return action.payload;
    case "FUND_TRANSFER":
      return action.payload;
    case "REMOVE_BUKTI_TRANSFER":
      return action.payload;
    case "DELETE_KYC_DOCUMENT":
      return action.payload;
    case "SUCCESS_SAVE_KYC_DOCUMENT":
      return action.payload;
    case "SUCCESS_SAVE_RELATION_DOCUMENT":
      return action.payload;
    case "DELETE_RELATION_DOCUMENT":
      return action.payload;
    case "REJECT_RIWAYAT_DOCUMENT":
      return action.payload;
    case "APPROVE_RIWAYAT_DOCUMENT":
      return action.payload;
    case "REJECT_RIWAYAT_KERJASAMA":
      return action.payload;
    case "APPROVE_RIWAYAT_KERJASAMA":
      return action.payload;
    case "SUCCESS_CREATE_CUSTOMER_RELATION":
      return action.payload;
    case "ERROR_CREATE_CUSTOMER_RELATION":
      return action.payload;
    case "APPROVE_KYC_DOCUMENT":
      return action.payload;
    case "REJECT_KYC_DOCUMENT":
      return action.payload;
    case "REQUEST_REGISTER_FINTECH":
      return action.payload;
    case "APPROVE_CALON_PEMINJAM":
      return action.payload;
    case "REJECT_CALON_PEMINJAM":
      return action.payload;
    case "PAY_INVOICE_FINANCING":
      return action.payload;
    case "SIGNUP_SUCCESS":
      return action.payload;
    case "SIGNUP_FAIL":
      return action.payload;
    case "CREATE_USER_SUCCESS":
      return action.payload;
    case "CREATE_USER_FAIL":
      return action.payload;
    case "COMPANY_REGISTRATION":
      return action.payload;
    case "REGISTER_COMPANY_SUCCESS":
      return action.payload;
    case "REGISTER_COMPANY_FAIL":
      return action.payload;
    case "SUCCESS_EDIT_ORDER_PENJUALAN":
      return action.payload;
    case "INIT_MESSAGE":
      return action.payload;
    case "SUCCESS_UPDATE_PROFILE":
      return action.payload;
    case "SUCCESS_UPDATE_PASSWORD":
      return action.payload;
    case "APPROVE_UPDATE_PASSWORD":
      return action.payload;
    case "FAILED_UPDATE_PASSWORD":
      return action.payload;
    case "SUCCESS_REQUEST_SETTLEMENT":
      return action.payload;
    case "ERROR_REQUEST_SETTLEMENT":
      return action.payload;
    case "SUCCESS_CREATE_REQUEST_SETTLEMENT":
      return action.payload;
    case "ERROR_CREATE_REQUEST_SETTLEMENT":
      return action.payload;
    case "FAIL_FETCH_COMPANY_ACCOUNT":
      return action.payload;
    case "ERROR_REFRESH_TOKEN":
      return action.payload;
    case "SUCCESS_REFRESH_TOKEN":
      return action.payload;
    default:
      return state;
  }
};
