import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const initAnInvoice = () => {
  return {
    type: "INIT_AN_INVOICE",
  };
};

export const getInvoice =
  ({ number }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.get("/api/v1/invoice", {
        params: {
          number,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      dispatch({ type: "GET_INVOICE", payload: response.data.data });
    };

export const getSelfBillingInvoice =
  ({ number }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.get("/api/v1/invoice/self/billing", {
        params: {
          number,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      dispatch({ type: "GET_INVOICE", payload: response.data.data });
    };

export const fintechGetInvoiceCustomerDetail = (id) => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/fintech/company", {
    params: {
      id,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_CUSTOMER_DETAIL", payload: response.data.data });
};

export const fintechGetInvoiceApprovalDate = (id) => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/fintech/approval/date", {
    params: {
      id,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_APPROVAL_DATE", payload: response.data.data });
};

export const getInvoiceFintech =
  ({ id }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.get("/api/v1/fintech/financing/invoice", {
        params: {
          id,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      dispatch({ type: "GET_INVOICE", payload: response.data.data });
    };

export const updateStatus =
  ({ invoice_id, type, status, number }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.put("/api/v1/invoice/status", {
        invoice_id,
        type,
        status,
      });
      if (response !== undefined) {
        if (response.data.status === "OK") {
          const response = await invoiceAPI.get("/api/v1/invoice", {
            params: {
              number,
            },
          });
          dispatch({ type: "GET_INVOICE", payload: response.data.data });
        }
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
      }
    };

export const sendEmailInvoice =
  ({ invoice_id, recipient }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.post("/api/v1/invoice/send", {
        invoice_ids: invoice_id,
        recipient,
      });
      if (response !== undefined) {
        if (response.data.status === "OK") {
          dispatch({
            type: "SEND_EMAIL_SUCCESS",
            payload: { status: "SEND_EMAIL_SUCCESS", message: response.data },
          });
        }
      }
    };

export const updateDocument =
  ({ invoice_id, type, file, number }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      var formData = new FormData();
      formData.append("file", file);
      formData.append("invoice_id", invoice_id);
      formData.append("type", type);
      const response = await invoiceAPI.post("/api/v1/invoice/document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response !== undefined) {
        if (response.data.status === "OK") {
          const response = await invoiceAPI.get("/api/v1/invoice", {
            params: {
              number,
            },
          });
          dispatch({ type: "GET_INVOICE", payload: response.data.data });
        }
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
      }
    };

export const updateInvoicePaymentDocument =
  ({ file, invoice_id }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      var formData = new FormData();
      formData.append("file", file);
      formData.append("invoice_id", invoice_id);
      const response = await invoiceAPI.put("/api/v1/invoice/payment", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .catch((e) => {
          dispatch({
            type: "ERROR_UPDATE_INVOICE_PAYMENT_DOCUMENT",
            payload: { status: "ERROR_UPDATE_INVOICE_PAYMENT_DOCUMENT", message: e.response.data.message },
          });
        });


      if (response !== undefined) {
        if (response.data.status === "OK") {
          dispatch({ type: "UPDATE_INVOICE_PAYMENT_DOCUMENT", payload: response.data.data });
        }
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
      }
    };




export const deleteDocument =
  ({ id, number }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.delete("/api/v1/invoice/document", {
        data: {
          document_id: id,
        },
      });
      if (response !== undefined) {
        if (response.data.status === "OK") {
          const getResponse = await invoiceAPI.get("/api/v1/invoice", {
            params: {
              number,
            },
          });
          dispatch({ type: "GET_INVOICE", payload: getResponse.data.data });
        }
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
      }
    };
