import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import InvoiceList from "layouts/invoice";
import NewInvoice from "layouts/newinvoice";
import DetailInvoice from "layouts/detailinvoice";
import RegisterBorrow from "layouts/registerborrow";
import Billing from "layouts/billing";
import Testing from "layouts/testing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ForgetPass from "layouts/authentication/reset-password/forget-password";
import ResetPassword from "layouts/authentication/reset-password/password-reset";
import Pengajuan from "layouts/pengajuan";
import DetailPengajuan from "layouts/detailpengajuan";
import WelcomePage from "layouts/welcomepage";
import PersetujuanPengajuan from "layouts/persetujuanpengajuan";
import KycPeminjam from "layouts/kycpeminjam";
import DaftarPendana from "layouts/daftarpendana";
import CalonPeminjam from "layouts/calonpeminjam";
import Pelanggan from "layouts/pelanggan";
import OrderPenjualan from "layouts/orderpenjualan";
import OrderPembelian from "layouts/orderpembelian";
import PembayaranCepat from "layouts/pembayarancepat";
import EmailSuccess from "layouts/emailSuccess";
import EmailError from "layouts/emailError";

import Icon from "@mui/material/Icon";
import DetailKycPeminjam from "layouts/detailkycpeminjam";
import DetailOrderPenjualan from "layouts/detailorderpenjualan";
import DetailOrderPembelian from "layouts/detailorderpembelian";

import NewOrderPenjualan from "layouts/neworderpenjualan";
import NewOrderPembelian from "layouts/neworderpembelian";
import RegisterCustomer from "layouts/registercompany";
import EditProfile from "layouts/editprofile";
import DaftarPengguna from "layouts/daftarpengguna";
import DetailPengguna from "layouts/detailpengguna";
import RiwayatKerjasama from "layouts/riwayatkerjasama";
import DetailDocsRiwayatKerjasama from "layouts/detailDocsRiwayatkerjasama";
import SettlementRequest from "layouts/settlementRequest";

const appRoutes = [
  {
    index: true,
    element: <Dashboard />,
    state: "dashboard",
    role: ["broker", "vendor", "finance", "operation", "manager"],
    business: ["broker", "vendor"],
  },
  {
    path: "/edit-profile",
    element: <EditProfile />,
    state: "dashboard",
    role: ["broker", "vendor", "finance", "operation", "manager"],
    business: ["broker", "vendor"],
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    state: "dashboard",
    key: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
    },
    role: ["broker", "vendor", "finance", "operation", "manager", "admin"],
    business: ["broker", "vendor"],
  },
  {
    sidebarProps: {
      displayText: "Penjualan",
      icon: <Icon fontSize="small">point_of_sale</Icon>,
    },
    role: ["broker", "vendor", "operation", "manager", "finance"],
    business: ["broker", "vendor"],
    child: [
      {
        path: "/orderpenjualan",
        element: <OrderPenjualan />,
        state: "orderpenjualan",
        key: "orderpenjualan",
        sidebarProps: {
          displayText: "Order Penjualan",
        },
        role: ["broker", "vendor", "operation", "manager"],
        business: ["broker", "vendor"],
      },
      {
        path: "/invoicepenjualan",
        element: <InvoiceList />,
        state: "invoicepenjualan",
        key: "invoicepenjualan",
        sidebarProps: {
          displayText: "Invoice Penjualan",
        },
        role: ["broker", "finance", "manager"],
        business: ["broker"],
      },
      {
        path: "/invoice",
        element: <InvoiceList />,
        state: "invoice",
        key: "invoice",
        sidebarProps: {
          displayText: "Invoice Penjualan",
        },
        role: ["borrower", "vendor"],
        business: ["vendor", "borrower"],
      },
      {
        path: "/pengajuan",
        element: <Pengajuan />,
        state: "pengajuan",
        key: "pengajuan",
        sidebarProps: {
          displayText: "Invoice Financing",
        },
        role: ["broker", "borrower", "manager"],
        business: ["broker", "borrower"],
      },
      {
        path: "/daftarquickpay",
        element: <PembayaranCepat />,
        state: "daftarquickpay",
        key: "daftarquickpay",
        sidebarProps: {
          displayText: "Invoice Financing",
        },
        role: ["vendor"],
        business: ["vendor"],
      },
    ],
  },
  {
    sidebarProps: {
      displayText: "Pembelian",
      icon: <Icon fontSize="small">shopping_cart</Icon>,
    },
    role: ["broker", "operation", "manager"],
    business: ["broker"],
    child: [
      {
        path: "/orderpembelian",
        element: <OrderPembelian />,
        state: "orderpembelian",
        key: "orderpembelian",
        sidebarProps: {
          displayText: "Order Pembelian",
        },
        role: ["broker", "operation", "manager"],
        business: ["broker"],
      },
      {
        path: "/orderfinancing",
        element: <Pengajuan />,
        state: "orderfinancing",
        key: "orderfinancing",
        sidebarProps: {
          displayText: "Order Financing",
        },
        role: ["broker", "borrower", "manager"],
        business: ["broker", "borrower"],
      },
      {
        path: "/pengajuanqp",
        element: <PersetujuanPengajuan />,
        state: "pengajuanqp",
        key: "pengajuanqp",
        sidebarProps: {
          displayText: "PayLater",
        },
        role: ["broker", "manager"],
        business: ["broker"],
      },
    ],
  },
  {
    path: "/pelanggan",
    element: <Pelanggan />,
    state: "pelanggan",
    key: "pelanggan",
    sidebarProps: {
      displayText: "Pelanggan",
      icon: <Icon fontSize="small">factory</Icon>,
    },
    role: ["borrower", "broker", "manager", "admin"],
    business: ["borrower", "broker"],
  },
  {
    path: "/pendana",
    element: <DaftarPendana />,
    state: "pendana",
    key: "pendana",
    sidebarProps: {
      displayText: "Daftar Pendana",
      icon: <Icon fontSize="small">account_balance</Icon>,
    },
    role: ["borrower", "broker", "manager"],
    business: [],
  },
  {
    path: "/pengajuanfin",
    element: <PersetujuanPengajuan />,
    state: "pengajuanfin",
    key: "pengajuanfin",
    sidebarProps: {
      displayText: "Persetujuan Pengajuan",
      icon: <Icon fontSize="small">published_with_changes</Icon>,
    },
    role: ["lender", "analyst"],
    business: ["fintech"],
  },
  {
    path: "/pembayaranqp",
    element: <PersetujuanPengajuan />,
    state: "pembayaranqp",
    key: "pembayaranqp",
    sidebarProps: {
      displayText: "Pembayaran PayLater",
      icon: <Icon fontSize="small">payment</Icon>,
    },
    role: ["lender"],
    business: ["fintech"],
  },
  {
    path: "/peminjam",
    element: <CalonPeminjam />,
    state: "peminjam",
    key: "peminjam",
    sidebarProps: {
      displayText: "Calon Peminjam",
      icon: <Icon fontSize="small">add_business</Icon>,
    },
    role: ["lender"],
    business: ["fintech"],
  },
  {
    path: "/kycpeminjam",
    element: <KycPeminjam />,
    state: "kycpeminjam",
    key: "kycpeminjam",
    sidebarProps: {
      displayText: "KYC Peminjam",
      icon: <Icon fontSize="small">assessment</Icon>,
    },
    role: ["admin", "sales"],
    business: ["kentara", "borrower"],
  },
  {
    path: "/daftar-pengguna",
    element: <DaftarPengguna />,
    state: "daftarpengguna",
    key: "daftarpengguna",
    sidebarProps: {
      displayText: "Daftar Pengguna",
      icon: <Icon fontSize="small">groups2</Icon>,
    },
    role: ["admin"],
    business: ["kentara", "borrower"],
  },
  {
    path: "/settlement-request",
    element: <SettlementRequest />,
    state: "settlementrequest",
    key: "settlementrequest",
    sidebarProps: {
      displayText: "Settlement Request",
      icon: <Icon fontSize="small">groups2</Icon>,
    },
    role: ["admin"],
    business: ["kentara", "borrower"],
  },

  // routes
  {
    type: "route",
    key: "detailpengguna",
    path: "/daftar-pengguna/:name/:type/:id",
    element: <DetailPengguna />,
    role: ["admin"],
    business: ["kentara", "borrower"],
  },
  {
    type: "route",
    key: "riwayatkerjasama",
    path: "/riwayat-kerjasama/:id",
    element: <RiwayatKerjasama />,
    role: ["lender"],
    business: ["fintech", "borrower", "vendor", "broker"],
  },
  {
    type: "route",
    key: "documentriwayatkerjasama",
    path: "/riwayat-kerjasama/:id/customer/:customerId/document/:customerFintechRelationId",
    element: <DetailDocsRiwayatKerjasama />,
    role: ["lender"],
    business: ["fintech", "borrower", "vendor", "broker"],
  },
  {
    type: "route",
    key: "registerborrow",
    path: "/registerborrow",
    element: <RegisterBorrow />,
    role: ["borrower", "broker", "finance", "manager"],
    business: ["borrower", "broker"],
  },
  {
    type: "route",
    key: "initial",
    path: "/",
    element: <Dashboard />,
    role: ["borrower", "admin", "lender", "broker", "vendor", "finance", "operation", "manager"],
    business: ["borrower", "partner", "fintech", "broker", "vendor", "kentara"],
  },
  {
    type: "route",
    key: "detailinvoice",
    path: "/detailinvoice/:number/:id",
    element: <DetailInvoice />,
    role: ["lender", "borrower", "vendor", "broker", "manager", "finance"],
    business: ["fintech", "borrower", "vendor", "broker"],
  },
  {
    type: "route",
    key: "detailorderpenjualan",
    path: "/detailorderpenjualan/:id",
    element: <DetailOrderPenjualan />,
    role: ["broker", "vendor", "lender", "operation", "manager"],
    business: ["broker", "vendor", "fintech"],
  },

  {
    type: "route",
    key: "detailorderpembelian",
    path: "/detailorderpembelian/:id",
    element: <DetailOrderPembelian />,
    role: ["broker", "vendor", "lender", "manager", "operation"],
    business: ["broker", "vendor", "fintech"],
  },
  {
    type: "route",
    key: "detailkycpeminjam",
    path: "/detailkycpeminjam/:id/:status",
    element: <DetailKycPeminjam />,
    role: ["admin", "sales"],
    business: ["kentara"],
  },
  {
    type: "route",
    key: "detailcalonpeminjam",
    path: "/detailcalonpeminjam/:id",
    element: <DetailKycPeminjam />,
    role: ["lender"],
    business: ["fintech"],
  },

  {
    type: "route",
    key: "detailinvoice",
    path: "/detailinvoice/:number",
    element: <DetailInvoice />,
    role: ["borrower", "vendor", "broker", "finance", "manager"],
    business: ["borrower", "vendor", "broker"],
  },
  {
    type: "route",
    key: "detailpengajuan",
    path: "/pengajuan/invoice/:request_id",
    element: <DetailPengajuan />,
    role: ["borrower", "broker", "manager"],
    business: ["borrower", "broker"],
  },
  {
    type: "route",
    key: "detailpengajuan",
    path: "/pengajuan/invoice/fintech/:request_id",
    element: <DetailPengajuan />,
    role: ["lender"],
    business: ["fintech"],
  },
  {
    type: "route",
    key: "detailpengajuan",
    path: "/pengajuan/purchase/:request_id",
    element: <DetailPengajuan />,
    role: ["broker", "manager"],
    business: ["broker"],
  },
  {
    type: "route",
    key: "detailpengajuan",
    path: "/pengajuan/purchase/fintech/:request_id",
    element: <DetailPengajuan />,
    role: ["lender"],
    business: ["fintech"],
  },
  {
    type: "route",
    key: "detailpengajuan",
    path: "/pengajuan/quickpay/:request_id",
    element: <DetailPengajuan />,
    role: ["vendor"],
    business: ["vendor"],
  },
  {
    type: "route",
    key: "detailpengajuan",
    path: "/pengajuan/quickpay/fintech/:request_id",
    element: <DetailPengajuan />,
    role: ["lender", "broker", "manager"],
    business: ["fintech", "broker"],
  },
  {
    type: "route",
    key: "neworderpenjualan",
    path: "/neworderpenjualan",
    element: <NewOrderPenjualan />,
    role: ["broker", "operation"],
    business: ["broker"],
  },
  {
    type: "route",
    key: "editorderpenjualan",
    path: "/editorderpenjualan/:id",
    element: <NewOrderPenjualan />,
    role: ["broker", "operation"],
    business: ["broker"],
  },
  {
    type: "route",
    key: "neworderpembelian",
    path: "/neworderpembelian",
    element: <NewOrderPembelian />,
    role: ["broker", "operation"],
    business: ["broker"],
  },
  {
    type: "route",
    key: "neworderpembelian",
    path: "/neworderpembelian/:soid/:sonumber/:projectname",
    element: <NewOrderPembelian />,
    role: ["broker", "operation"],
    business: ["broker"],
  },

  {
    type: "route",
    key: "newinvoice",
    path: "/newinvoice",
    element: <NewInvoice />,
    role: ["borrower", "vendor", "broker", "finance"],
    business: ["borrower", "vendor", "broker"],
  },
  {
    type: "route",
    key: "newinvoice",
    path: "/newinvoice/:customerid/:customername/:soid/:sonumber/:projectname",
    element: <NewInvoice />,
    role: ["borrower", "vendor", "broker", "finance"],
    business: ["borrower", "vendor", "broker"],
  },
  {
    type: "route",
    key: "editinvoice",
    path: "/editinvoice/:number",
    element: <NewInvoice />,
    role: ["borrower", "vendor", "finance", "broker"],
    business: ["borrower", "vendor", "broker"],
  },
  {
    type: "route",
    key: "signinoauth",
    path: "/oauth/signin",
    element: <SignIn />,
    role: ["borrower", "admin", "lender", "broker"],
    business: ["borrower", "partner", "fintech", "broker", "vendor", "kentara"],
  },
  {
    type: "route",
    key: "signin",
    path: "/signin",
    element: <SignIn />,
    role: ["borrower", "admin", "lender", "broker", "operation", "finance", "manager"],
    business: ["borrower", "partner", "fintech", "broker", "vendor", "kentara"],
  },
  {
    type: "route",
    key: "forget-password",
    path: "/forget-password",
    element: <ForgetPass />,
    role: ["borrower", "admin", "lender", "broker", "operation", "finance", "manager"],
    business: ["borrower", "partner", "fintech", "broker", "vendor", "kentara"],
  },
  {
    type: "route",
    key: "reset-password",
    path: "/password/reset",
    element: <ResetPassword />,
    role: ["borrower", "admin", "lender", "broker", "operation", "finance", "manager"],
    business: ["borrower", "partner", "fintech", "broker", "vendor", "kentara"],
  },
  {
    type: "route",
    key: "signup",
    path: "/signup",
    element: <SignUp />,
    role: [],
    business: [],
  },
  {
    type: "route",
    key: "registercompany",
    path: "/company/registration",
    element: <RegisterCustomer />,
    role: [],
    business: [],
  },
];

export default appRoutes;
