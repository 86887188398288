/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import CustomFooter from "components/LVCustomFooter";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogContentText, Icon } from "@mui/material";

function LVCalonPeminjam(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [riwayatModal, setRiwayatModal] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState({ state: false, id: 0 });


  const [filterTimeout, setFiltertimeout] = useState();

  const filterHandlerDebouncing = (params) => {
    clearTimeout(filterTimeout);
    setFiltertimeout(setTimeout(() => filterHandler(params), 500));
  }

  const resetFilter = (params) => {
    props.resetFilter(params);
  }

  const filterHandler = (params) => {
    if (params.items[0] === undefined) {
      resetFilter();
      return;
    }
    let condition = params.items[0];

    if (condition.value === undefined || condition.value === "") {
      return;
    }

    if (condition.columnField === "company_name") {
      props.keyword(condition.value);
    }

  }


  useEffect(() => {
    if (props.businessType === "fintech") {
      setColumnVisibilityModel({
        ...columnVisibilityModel,
        required: false,
      });
    }
  }, []);

  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <span>
          {params.api.getRowIndex(params.row.id) +
            1 +
            (parseInt(props.currentPage) - 1) * parseInt(props.pageSize)}
        </span>
      ),
    },
    {
      field: "company_name",
      headerName: "Nama Calon Peminjam",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Link to={`/detailcalonpeminjam/${encodeURIComponent(params.row.company_id)}`}>
          <span>{params.value}</span>
        </Link>
      ),
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.value === "created") {
          return <span>Baru</span>;
        } else if (params.value === "approved") {
          return <span>Disetujui</span>;
        } else if (params.value === "rejected") {
          return <span>Ditolak</span>;
        }
      },
      width: 200,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Tindakan",
      sortable: false,
      align: "center",
      width: 400,
      getActions: (params) => [
        <MDBox display="flex" sx={{ justifyContent: "center" }}>
          <Link to={`/riwayat-kerjasama/${encodeURIComponent(params.row.company_id)}`}>
            <MDButton sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold", marginRight: "10px" }}>
              Riwayat Kerjasama
            </MDButton>
          </Link>
          {params.row.status === "created" ? (
            <>
              <MDButton
                sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold", marginRight: "10px" }}
                onClick={(e, v) => {
                  props.onPersetujuan(params.row.relation_id);
                }}
              >
                Setujui
              </MDButton>
              <MDButton
                sx={{ color: "#F63939", fontSize: "12px", fontWeight: "bold" }}
                onClick={() => setOpenApproveDialog({ state: true, id: params.row.relation_id })}
              >
                Tolak
              </MDButton>
            </>
          ) : null}
        </MDBox>,
      ],
    },
    {
      field: "report",
      headerName: "Laporan",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        <Link to={`#}`}>
          <span>LAPORAN</span>
        </Link>
      },
      width: 200,
    },
  ];

  const rows = [
    { id: 1, no: 1, name: "No Telepon", status: "Full Submission", data: "123" },
    { id: 2, no: 2, name: "KTP Direktur", status: "Partial Submission", data: "123" },
  ];

  const mapSortingColumn = (column) => {
    let map = {
      company_name: "name",
    };

    if (map.hasOwnProperty(column)) {
      return map[column];
    } else {
      return column;
    }
  };

  const sortingHandler = (params) => {
    if (params == [] || params === 'undefined') {
      return;
    }
    props.order(mapSortingColumn(params[0].field), params[0].sort);
  };

  function CustomFooterProps() {
    return (
      <CustomFooter
        page={props.totalPage}
        selectPage={props.selectPage}
        nextPage={props.nextPage}
        prevPage={props.prevPage}
        currentPage={props.currentPage}
        firstPage={props.firstPage}
        lastPage={props.lastPage}
      />
    );
  }

  return (
    <MDBox
      mt={5}
      sx={{
        height: 750,
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={props.rows}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        disableSelectionOnClick
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
        sortingMode="server"
        onSortModelChange={sortingHandler}

        filterMode="server"
        onFilterModelChange={filterHandlerDebouncing}

        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination
        components={{ Footer: CustomFooterProps }}
      />
      <Dialog
        open={openApproveDialog.state === true}
        onClose={() => setOpenApproveDialog({ state: false, id: 0 })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MDBox display="flex" ml={2} mt={2}>
          <Icon color="info" fontSize="large">
            check_circle_outline
          </Icon>
          <MDBox sx={{ fontSize: 18, fontWeight: "bold", my: "auto", ml: 2 }}>
            Daftar ke Pendana
          </MDBox>
        </MDBox>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah Anda yakin ingin menyetujui peminjam ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => setOpenApproveDialog({ state: false, id: 0 })}
            variant="outlined"
            color="info"
            size="small"
          >
            Batal
          </MDButton>
          <MDButton
            onClick={() => props.onPenolakan(openApproveDialog.id)}
            variant="contained"
            color="error"
            size="small"
            autoFocus
          >
            Tolak
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default LVCalonPeminjam;
